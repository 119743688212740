body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
}

/* override commandbar's default announcement banner colors
   because doing so using no-code requires a higher subscription tier :(
*/
#commandbar-wrapper [data-testid^='commandbar-banner-'] {
  background-color: var(--base-accent);
  border-radius: 0;
  border: 0;
  
  [class^='commandbar-toplevel-'] {
    color: #FFFFFF;
		
    path stroke {
      stroke: #FFFFFF;
    }

    button:not([data-testid='nudge-step-close-button']) {
      background-color: #FFFFFF;
      color: var(--base-accent);

      &:hover {
        background-color: #FFFFFFcc;
      }
    }
        
    button[data-testid='nudge-step-close-button']:hover {
      background-color: var(--base-accent);
      opacity: 0.3;
    }
  }
}